<template>
  <div class="author" v-show="isShow">
    <div class="content">
      <p class="title title-1">需要您授权以下信息登录商城</p>
      <p class="title title-2">您的手机号：{{ mobileNumber }}</p>
    </div>
    <div class="bottom">
      <div class="btns">
        <div class="agree-btn"
          @click="agreeAction">同意</div>
        <p class="hr"></p>
        <div class="refuse" @click="refuseAction">拒绝</div>
      </div>
      <div class="privacy-text">
        <span :class="{ checked: isChecked }" @click="isChecked = !isChecked">
          <img src="~@/assets/images/leaflet/dui.png" v-show="isChecked" alt="">
        </span>
        <div class="text">
          同意授权表示同意
          <!-- <a>《用户授权协议》</a>、 -->
          <a href="//mall.juzifenqi.com/agreement/user_reg_agreement.html">《注册协议》</a>、
          以及 <a href="//mall.juzifenqi.com/agreement/protocol_PrivacyProtection.html">《隐私政策》</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { getJuZTokenAction, getRequestApi } from '@/api/base';
import { isString } from '@/utils';

export default {
  name: 'Author',
  data() {
    return {
      isChecked: false,
      authToken: '',
      mobileNumber: 0,
      type: 1, // ios // android
      appInfo: {},
      isShow: false,
      userId: getAppStatus().cuserId,
      flag: '',
    };
  },
  computed: {
    userIds() {
    // "6bbf384d-dacd-472e-8c9f-d013c59d20b9"
    // return getAppStatus().cuserId;
      // console.log(getAppStatus(), 'getAppStatus----');
      // return '8783db39-e3e5-4a71-ac9a-a86ddfadf913';
      return getAppStatus().cuserId;
      // return '7d4cc35f-4d15-44bc-8642-6cd0fafe7a47';
    },
  },
  created() {},
  mounted() {
    document.title = '信息授权';
    let isClick = localStorage.getItem('isClick');
    if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
      this.flag = window.ios?.jzIsLogin();
      this.appInfo = window.ios?.jzAppInfo();
      this.mobileNumber = window.ios?.jzPhoneNum();
      if (!this.userId) {
        this.userId = sessionStorage.getItem('userId');
        this.appInfo = sessionStorage.getItem('appInfo');
        this.mobileNumber = sessionStorage.getItem('phoneNum');
        this.flag = sessionStorage.getItem('isLogin');
        callAppHandler('jzUserId', null, (res) => {
          this.userId = res;
          sessionStorage.setItem('userId', res);
        });
        callAppHandler('jzAppInfo', null, (res) => {
          this.appInfo = res;
          sessionStorage.setItem('appInfo', res);
        });
        callAppHandler('jzPhoneNum', null, (res) => {
          this.mobileNumber = res;
          sessionStorage.setItem('phoneNum', res);
        });
        callAppHandler('jzIsLogin', null, (res) => {
          sessionStorage.setItem('isLogin', res);
          this.flag = res;
          let { userId } = this;
          this.isShow = isClick !== (userId + '_1');
          this.initData();
        });
      } else {
        let { userId } = this;
        this.isShow = isClick !== (userId + '_1');
        this.initData();
      }
    } else {
      let { userId } = this;
      this.isShow = isClick !== (userId + '_1');
      this.initData();
    }
  },
  methods: {
    initData() {
      const { userId } = this;
      sessionStorage.setItem('userId', userId);
      let { flag } = this;
      try {
        if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
          // this.appInfo = window.ios.jzAppInfo();
          // this.mobileNumber = window.ios.jzPhoneNum();
          console.log(this.appInfo, 'appInfo');
          this.type = 1;
        } else if (/android/i.test(navigator.userAgent)) {
          this.appInfo = window.android.jzAppInfo();
          this.mobileNumber = window.android.jzPhoneNum();
          flag = window.android.isUserRegistered();
          this.type = 2;
        } else {
          this.appInfo = {
            name: 'com.jz.youyu',
            channel: '官网',
            version: '5.1.0',
            osType: 1,
          };
          this.appInfo = JSON.stringify(this.appInfo);
        }

        if (!flag) {
          this.$toast({
            content: '请登录!',
            duration: 3000,
          });
          setTimeout(() => {
            localStorage.removeItem('isClick');
            window?.ios?.jzClose();
            window?.android?.jzClose();
          }, 3500);
          return false;
        }
        if (this.mobileNumber) {
          let reg = /^(\d{3})\d*(\d{4})$/;
          if (Number(this.mobileNumber)) {
            this.mobileNumber = this.mobileNumber.toString();
          }
          this.mobileNumber = this.mobileNumber.replace(reg, '$1****$2');
        }
        this.getJzToken();
      } catch (e) {
        console.log(e);
      }
    },
    async getJzToken() {
      let res;
      try {
        if (isString(this.appInfo)) {
          this.appInfo = JSON.parse(this.appInfo);
        }
        let {
          name, channel, version, osType,
        } = this.appInfo;
        const { type } = this;
        // channel = decodeURI(channel);
        let data = {
          type,
          appName: name,
          channel,
          version,
          osType,
        };
        res = await getJuZTokenAction(data);
      } catch (err) {
        console.log(err);
        return this.$toast(err?.desc || '网络异常');
      }
      if (res.code === 1) {
        this.authToken = res?.results?.data?.cherryToken;
        let loginId = res?.results?.loginId;
        let md5Code = res?.results?.md5Code;
        localStorage.setItem('jzToken', this.authToken);
        localStorage.setItem('loginId', loginId);
        localStorage.setItem('md5Code', md5Code);
        this.getRequest();
        const { userId } = this;
        let isClick = localStorage.getItem('isClick');
        isClick === (userId + '_1') && this.jumpUrl();
      }
    },
    refuseAction() {
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        window.ios.jzClose();
      } else if (/android/i.test(navigator.userAgent)) {
        window.android.jzClose();
      } else {
        this.$toast({ content: '请在移动端打开!' });
      }
    },
    agreeAction() {
      if (!this.isChecked) {
        return this.$toast({ content: '请同意授权!' });
      }
      const { userId } = this;
      localStorage.setItem('isClick', `${userId}_1`);
      this.jumpUrl();
    },
    jumpUrl() {
      const rootUrl = window.location.host;
      const { authToken } = this;
      const baseUrl = rootUrl.includes('.test.') ? '//sandbox-cherry-youyu.jzhlkj.com/hl-h5-all/newBorrowMoney_v2/webapp/main/index.html'
        : '//cherry-youyu.jzhlkj.com/hl-h5-all/newBorrowMoney_v2/webapp/main/index.html';
      window.open(`${baseUrl}?token=${authToken}`, '_self');
    },
    async getRequest() {
      let res;
      let { appInfo, type } = this;
      if (isString(appInfo)) {
        appInfo = JSON.parse(appInfo);
      }
      let loginId = localStorage.getItem('loginId');
      let md5Code = localStorage.getItem('md5Code');
      let {
        name, channel, version, osType,
      } = appInfo;
      const params = {
        loginId,
        version,
        channel,
        appName: name,
        osType,
        singToken: '12',
        codeId: 'JUZ-vhSS5VL',
        type: -1,
        md5Code,
      };
      // appInfo.loginId = loginId;
      // appInfo = Object.assign(appInfo, params);
      try {
        res = await getRequestApi(params);
      } catch (e) {
        console.log(e);
      }
    },
  },
  destroyed() {
    sessionStorage.removeItem('userId');
  },
};
</script>
<style scoped lang="scss">
  .author {
    width: 100%;
    height: 100%;
    position: relative;
    font-family: PingFangSC-Medium, PingFang SC;
    overflow: hidden;
    .content {
      font-weight: 500;
      color: #333333;
      margin: 108px 0 0 64px;
      .title-1 {
        font-size: 40px;
        line-height: 56px;
      }
      .title-2 {
        font-size: 36px;
        margin-top: 40px;
        line-height: 50px;
      }
    }
    .bottom {
      position: absolute;
      bottom: 108px;
      width: 100%;
      .btns {
        width: 100%;
        text-align: center;
        .agree-btn {
          width: 350px;
          height: 96px;
          background: linear-gradient(179deg, #F78987 0%, #F5706E 100%);
          border-radius: 57px;
          line-height: 96px;
          text-align: center;
          font-size: 32px;
          font-weight: 500;
          color: #fff;
          margin: auto;
          display: block;
          text-decoration: none;
        }
        .hr {
          padding-bottom: 40px;
        }
        .refuse {
          font-size: 32px;
          font-weight: 500;
          color: #666666;
          line-height: 45px;
          // margin-top: 40px;
        }
      }
      .privacy-text {
        margin: 0 64px;
        padding-top: 149px;
        font-size: 24px;
        line-height: 33px;
        color: #999;
        display: flex;
        .text {
          width: 575px;
        }
        a {
          color: #EB5F5F;
        }
        span {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          border: 2px solid #78797A;
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.checked {
            background-color: #EB5F5F;
            border: 2px solid #EB5F5F;
          }
          img {
            width: 100%;
          }
        }
      }
    }
  }
</style>
